import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import ReportLanding from "../components/report-landing/report-landing"
import "../components/Pagination/pagination.scss"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import Pagination from "../components/Pagination/Pagination"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"
function Blog({ data, pageContext }) {
  
  const postData = data.allWpPost?.nodes
  const imageData = data?.wp?.siteSettings?.siteSettings?.globalInnerHero

  //  page context data
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
  const nextPage = currentPage + 1
  // pagination data
  const paginationData = {
    currentPage,
    numPages,
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }
  const changePage = e => {
    navigate(`/blog/${e.target.value}`)
  }
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);
  return (
    <DefaultLayout data={data}>
      <div className="rs-breadcrumbs sec-color">
        <GatsbyImage
          image={getImage(imageData?.backgroundImage?.localFile)}
          alt={"Saigon Raiders Club"}
          className="inner-img"
        />
        <div className="breadcrumbs-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center"
               data-aos="fade-up"
               data-aos-offset="100"
               data-aos-delay="5"
              >
                <h1 className="page-title">Saigon Raiders: Blog</h1>
                <ul>
                  <li>
                    <a className="active" href="/blog">
                      News Home &#187;{" "}
                    </a>
                  </li>
                  <li> News</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12" style={{paddingTop: "100px"}}>
        <div className="default-pagination text-center">
          <ul>
            <select onChange={changePage} className="select-box">
              <option value={null}>Select category</option>
              <option value="news">News</option>
              <option value="match-reports">Match Reports</option>
            </select>
          </ul>
        </div>
      </div>
      <ReportLanding postData={postData} />
      <Pagination paginationData={paginationData} base={"blog"} />
    </DefaultLayout>
  )
}
export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    wp {
      siteSettings {
        siteSettings {
          globalInnerHero {
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, height: 380)
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        uri
        title
        date(formatString: "DD MMMM, YYYY")
        slug
        customFields {
          date
          score
          time
          title
        }
        excerpt
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 450, height: 383)
              }
            }
          }
        }
      }
    }
  }
`
export default Blog
